<template>
  <li>
    <div
      v-if="isFolder"
      class="flex cursor-pointer"
      :class="folderContainerClass"
      @click="handleClick"
    >
      <div
        class="my-1 mr-2"
        :class="folderIconContainerClass"
      >
        <slot
          v-if="toggleTree"
          name="folderOpenIcon"
        >
          <img
            v-if="openFolderIconProperty"
            :src="nodeData[openFolderIconProperty]"
          >
          <template v-else>
            -
          </template>
        </slot>
        <slot
          v-else
          name="folderCloseIcon"
        >
          <img
            v-if="closeFolderIconProperty"
            :src="nodeData[closeFolderIconProperty]"
          >
          <template v-else>
            +
          </template>
        </slot>
      </div>
      <span
        class="inline-block"
        :class="isNodeSelected ? selectedNodeClass : ''"
      >
        {{ nodeData[nameProperty] }}
      </span>
    </div>
    <ul
      v-if="isFolder"
      v-show="toggleTree"
      class="list-none ml-4"
    >
      <folder
        v-for="(childNodeData, cNIndex) in nodeData[subTreeProperty]"
        :key="childNodeData.id || 'node' + cNIndex"
        :node-data="childNodeData"
        v-bind="{...folderProps, selectedNode}"
        @nodeSelected="emitNodeSelected($event)"
      >
        <template
          v-for="(_, name) in slots"
          #[name]
          :key="'slot' + name"
        >
          <slot :name="name" />
        </template>
      </folder>
    </ul>
    <file
      v-else
      :node-data="nodeData"
      v-bind="{...fileProps, selectedNode}"
      @nodeSelected="emit('nodeSelected', $event)"
    >
      <template #fileIcon>
        <slot name="fileIcon" />
      </template>
    </file>
  </li>
</template>

<script>
import { defineAsyncComponent, computed, watch, onMounted } from 'vue';
import useToggle from '@/hooks/toggle.js';

export default {
    name: 'Folder',

    components: {
        Folder: defineAsyncComponent(() => import('./Folder.vue')),
        File: defineAsyncComponent(() => import('./File.vue'))
    },

    props: {
        nodeData: {
            type: Object,
            required: true,
            description: 'data of single node in the directory tree'
        },
        nameProperty: {
            type: String,
            default: 'styleGuideName',
            description: 'Property used for name of folder(default is name)'
        },
        subTreeProperty: {
            type: String,
            default: 'children',
            description: 'Property used for sub-tree(default is children)'
        },
        toggleProperty: {
            type: String,
            default: 'toggle',
            description: 'Property used for toggling node(default is toggle)'
        },
        openFolderIconProperty: {
            type: String,
            default: '',
            description: 'Property for opened folder icon url'
        },
        closeFolderIconProperty: {
            type: String,
            default: '',
            description: 'Property for closed folder icon url'
        },
        fileProps: {
            type: Object,
            default: () => {},
            description: 'File component props object(check file.vue for supported props)'
        },
        selectedNode: {
            type: Object,
            default: () => {},
            description: 'data of selected node from tree'
        },
        selectedNodeClass: {
            type: String,
            default: '',
            description: 'If selected node matches this node, applies specified classes'
        },
        folderIconContainerClass: {
            type: String,
            default: '',
            description: 'classes applied to folder icons container'
        },
        folderContainerClass: {
            type: String,
            default: '',
            description: 'classes applied to folder container'
        }
    },

    emits: [
        'nodeSelected'
    ],

    setup (props, { slots, emit }) {
        const { toggle: toggleTree, handleToggle: handleTreeToggle } = useToggle();
        const isFolder = computed(() => props.nodeData && props.subTreeProperty in props.nodeData);

        onMounted(() => {
            updateToggleTree();
        });
        const emitNodeSelected = (e) => {
            emit('nodeSelected', e);
        };
        watch(
            () => props.nodeData,
            () => {
                updateToggleTree();
            },
            { deep: true }
        );
        const updateToggleTree = () => {
            if (props.toggleProperty in props.nodeData && props.nodeData[props.toggleProperty] !== toggleTree.value) {
                toggleTree.value = props.nodeData[props.toggleProperty];
            }
        };
        const handleClick = () => {
            handleTreeToggle();
            if (props.toggleProperty in props.nodeData) {
                // eslint-disable-next-line
                props.nodeData[props.toggleProperty] = toggleTree.value;
            }
            // avoid emitting nodeSelected event if same node is selected
            if (!props.selectedNode || (props.selectedNode.id !== props.nodeData.id)) {
                emit('nodeSelected', props.nodeData);
            }
        };

        const isNodeSelected = computed(() => props.selectedNode && props.selectedNode.id && props.nodeData.id ? props.selectedNode.id === props.nodeData.id : false);

        // eslint-disable-next-line
        const { nodeData, selectedNode, ...folderProps } = props;

        return {
            isFolder,
            toggleTree,
            handleTreeToggle,
            handleClick,
            slots,
            emit,
            folderProps,
            isNodeSelected,
            emitNodeSelected
        };
    }
};
</script>
